<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="open">
        <div class="flix-form-group" v-if="!open && !deleted">
          <a href="#" class="flix-btn flix-btn-danger flix-btn-xs" @click.prevent="open = !open">
            <flixIcon id="bin" /> {{ $tc('message.appointments', 1) }} #{{ entry.booking_id }} {{ $t('message.delete') }}
          </a>
        </div>
        <div v-if="open">
          <span class="flix-text-danger">{{ $t('message.allDataWillBeDeleted') }}</span> <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="function () { setDelete() }">{{ $t('message.yes') }}</a> <a class="flix-btn flix-btn-xs flix-btn-default" href="#" @click.prevent="open = false">{{ $t('message.no') }}</a>
        </div>
        <transition name="flixFadeIn">
          <div :key="deleted" v-if="deleted">
            <div class="flix-alert flix-alert-success">{{ $t('message.successfullDeleted') }}</div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    entry: Object,
    index: Number
  },
  data () {
    return {
      open: false,
      variables: this.$getUserVariables(),
      deleted: false
    }
  },
  methods: {
    setDelete () {
      this.$flix_post({
        url: 'booking/delete',
        data: {
          user: this.variables.user.md5_id,
          ID: this.entry.ID,
          event_id: this.entry.form
        },
        callback: function (ret) {
          this.$store.commit('setDelete', {
            form: this.entry.form,
            id: this.index,
            callback: function () {
              this.open = false
              this.deleted = true
              setTimeout(function () {
                if (this.variables.state === 'share') {
                  this.$router.push({ name: 'sharesBookingCalendar' })
                } else {
                  this.$router.push({ name: 'dashboardSettings' })
                }
              }.bind(this), 3000)
            }.bind(this)
          })
        }.bind(this)
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
